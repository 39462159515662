<template>
  <div class="row border-bottom border-top bg-white ml-0 pr-4 mt-n2">
    <div class="col-12 py-3">
      <div class="d-flex">
        <template v-if="signatures.length">
          <span class="font-weight-bold" v-for="signature, index in doc.attachment.signatures" :key="signature.code">
            {{signature.organization | organization}} {{signature.name}}
            <span v-if="doc.attachment.signatures.length &gt; index + 1">, </span>
          </span>
        </template>
        <template v-else><span class="font-weight-bold">{{doc.from}}</span></template>
      </div>
      <div class="d-flex">
        <div class="text-truncate">
          <span>{{ doc.subject }}</span>
        </div>
      </div>
    </div>
    <div class="col-12 overflow-hidden">
      <p>{{ doc.message }}</p>
      <div class="small text-muted">Створено {{ doc.timestamp | timestamp }}</div><span class="text-monospace">{{ doc.code }}</span>
      {{ doc.from | caps }}
      <section v-if="doc.attachment">
        <div class="mt-3">
          <div class="small text-muted">Оригінальний документ</div>
          <i class="far fa-fw" :class="getIcon"></i>
          <span class="text-primary" cursor-pointer @click="() => downloadAttachment(doc.attachment.originalPath, '')">
            {{ doc.attachment.name }}
          </span>
        </div>

        <div v-if="doc.attachment.path">
          <div class="mt-3">
            <div class="small text-muted">Підписаний документ</div>
            <i class="far fa-fw" :class="getIcon"></i>
            <span class="text-primary" cursor-pointer @click="() => downloadAttachment(doc.attachment.path, 'sign')">
              {{ doc.attachment.name }}
            </span>
          </div>
        </div>

        <div v-if="doc.public && doc.publicPath">
          <div class="mt-3">
            <div class="small text-muted">Публічний документ</div>
            <i class="far fa-fw" :class="getIcon"></i>
            <a download :href="doc.publicPath">
              {{ doc.attachment.name }}
            </a>
          </div>
        </div>

        <div v-if="doc.public && doc.publicUrl && generateUrl">
          <div class="mt-3">
            <i class="far fa-fw" :class="getIcon"></i>
            <a :href="generateUrl">
              Публічне посилання
            </a>
            <a title="Скопіювати в буфер" @click="() => copyUrl(generateUrl)">
              <i cursor-pointer class="far fa-copy ml-2"></i>
            </a>
          </div>
        </div>
        <div class="p-2"></div>
        <section v-if="signatures.sign &amp;&amp; signatures.sign.length">
          <div class="small text-muted">Підписано</div>
          <section v-for="signature in signatures.sign" :key="signature.code">
            <i class="far fa-fw mr-2" :class="[signature.date ? 'fa-signature' : 'fa-clock']"></i>
            <span class="text-monospace">{{ signature.code }}</span>
            {{ signature.name | caps }}
            <div class="small">{{ signature.date | timestamp }}</div>
            <div class="p-1"></div>
          </section>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      icons: {
        "doc": "fa-file-word",
        "pdf": "fa-file-pdf",
        "txt": "fa-file-alt",
        "rtf": "fa-file-alt"
      },
      doc: {},
    }
  },
  computed: {
    generateUrl() {
      return `${location.origin}/public/${this.$route.params.id}`
    },
    getIcon () {
      return this.icons[this.doc.attachment.type] || this.icons[this.doc.attachment.name.split('.')[1]]
    },
    signatures() {
      const signatures = this.doc?.attachment?.signatures || [];
      return {
        sign: signatures.filter(function (item) {
          return item.date;
        }),
        length: signatures.length
      };
    },
  },
  methods: {
    ...mapActions('docs', ['getPublicDocument']),
  },
  async beforeMount() {
    const data = await this.getPublicDocument(this.$route.params.id);
    if (data.document) {
      this.doc = data.document;
    }
  },
}
</script>